// import jphoto from "/Users/jessicayee/Documents/jessica-portfolio/client/src/assets/jphoto.jpeg";
import jphoto from "../about-files/jphoto.jpeg";
import "../about-files/About.css";

export default function About() {
  return (
    <div className="about-parent">
      <div className="about-left-container">
        <h1 id="jess-greeting">Hi, I'm Jess!</h1>
        <p id="brand-statement">
          I am a results-driven and deeply committed full-stack software
          engineer with expertise in the development of accessibility-centric
          products, encompassing strategic accessibility planning and hands-on
          technical implementation. My professional journey has encompassed
          roles in customer success, program operations, and community
          management within the domains of education startups and healthcare
          institutions, bringing in a strong work ethic and a resourceful,
          self-starting approach to all endeavors.
        </p>
        {/* // TODO: Comment back in once projects are viewable again */}
        {/* <button
          id="my-projects-btn"
          onClick={() => {
            window.location.href = "#projects-anchor";
          }}
        >
          My Projects
        </button> */}
      </div>
      <img
        src={jphoto}
        id="jphoto"
        alt="Headshot of Jess, wearing glasses and coral pink top"
      />
    </div>
  );
}
