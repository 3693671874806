import "../experience-files/Experience.css";

export default function Experience() {
  return (
    <div className="experience-parent" id="experiences">
      <div className="tech-stack-container">
        <h2 id="tech-stack-title">My Tech Stack</h2>
        <p className="tech-stack-list">
          Ruby | JavaScript | Typescript | Python
        </p>
        <p className="tech-stack-list">SQL | HTML | CSS</p>
        <p className="tech-stack-list">React | Ruby on Rails | Express</p>
        <p className="tech-stack-list">Django | Elastic | Metabase</p>
        <p className="tech-stack-list">MongoDB | PostgreSQL</p>
        <p className="tech-stack-list">Heroku | Docker | Render</p>
        <p className="tech-stack-list">Git | NodeJS</p>
      </div>
      <div className="experiences-right-container">
        <h2 className="experiences-right-title">Tools I Use</h2>
        <p className="tech-stack-list">Cypress | Github</p>
        <p className="tech-stack-list">
          Axe Devtools | WAVE accessibility checker | VoiceOver
        </p>
        <p className="tech-stack-list">
          Storybook | Figma | Whimsical | YARD documentation
        </p>
        <p className="tech-stack-list">Jira/Confluence | Asana | Trello</p>
        <p className="tech-stack-list">Google Suite | Microsoft Suite</p>
      </div>
    </div>
  );
}
