import NavSection from "../src/components/nav-files/Nav";
import About from "./components/about-files/About";
// ignoring for now since we will re-add Project when things look good
// eslint-disable-next-line
import Project from "./components/project-files/Project";
import Experience from "./components/experience-files/Experience";
import Contact from "./components/contact-files/Contact";
import Footer from "./components/footer-files/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <NavSection />
      <About />
      {/* <Project /> */}
      <Experience />
      <div className="bottom-section">
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
