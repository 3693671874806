import "../footer-files/Footer.css";

export default function Footer() {
  return (
    <div className="footer-parent">
      <a
        href="https://www.linkedin.com/in/jessica-m-yee/"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="https://i.imgur.com/3WZtamO.png"
          alt="linkedin"
          className="footer-photo"
        />{" "}
      </a>

      <a
        href="https://github.com/jessicamyee"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="https://i.imgur.com/7fUFddd.png"
          alt="github"
          className="footer-photo"
        />
      </a>
    </div>
  );
}
