import "../contact-files/Contact.css";

export default function Contact() {
  return (
    <div className="contact-parent" id="contact">
      <h2 id="contact-title">Contact Info:</h2>
      <p>Feel free to message me on LinkedIn!</p>
    </div>
  );
}
