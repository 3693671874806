import { Navbar, Nav } from "react-bootstrap";
import "../nav-files/Nav.css";

export default function NavSection() {
  const navOptions = (
    <>
      {/* <Nav.Link className="nav-tabs" href="#projects-anchor">
        Projects
      </Nav.Link> */}
      <Nav.Link className="nav-tabs" href="#experiences">
        Experiences/Tech Stack
      </Nav.Link>
      <Nav.Link className="nav-tabs" href="#contact">
        Contact
      </Nav.Link>
      {/* <Nav.Link
        className="nav-tabs"
        target="_blank"
        href="https://drive.google.com/file/d/1Ijpgfq_YZLV-p_eeURvetuLB_unz65Lw/view?usp=sharing"
      >
        Resume
      </Nav.Link> */}
    </>
  );

  return (
    <Navbar className="nav-section sticky-top" expand="lg">
      <Navbar.Brand href="#" id="jessica">
        Jessica Yee
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="font-weight-bold">{navOptions}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
